//https://cookieconsent.insites.com/documentation/disabling-cookies/
//https://cookieconsent.insites.com/documentation/javascript-api/
var globalCurrentDomain = "https://paag.org";

window.addEventListener("load", function () {
    //Set up cookie notification on load   
    var p;
        cookieconsent.initialise({
            "showLink": true,
            "position": "top",
            "type": "opt-in",
            "container": document.getElementById("cookie-msg"),
            "content": {
                "message": "We would like to use cookies to store information on your computer in order to help us make this website better. You can change your settings at any time.",
                "dismiss": "Dismiss",
                "allow": "Accept",
                "link": "View cookie settings",
                "href": "../privacy"
            },
            onPopupOpen: function () {
            },
            onPopupClose: function () {
                document.location.href = document.location.href;
            },
            onInitialise: function (status) {
                var hasConsentCookie = document.cookie.indexOf("paag.org:Consent") !== -1;
                if (!hasConsentCookie) {
                    setTimeout(function() {
                        //  Force user choice again
                        p.revokeChoice();
                        document.location.reload(true);
                    }, 1000);
                }
            },
            onStatusChange: function (status) {
                var cookieGroups = ["StrictlyNecessary"];

                if (status === "allow") {
                    cookieGroups = ["StrictlyNecessary", "PerformanceAnalytics"];
                }

                setConsentCookie(cookieGroups, globalCurrentDomain);
            },
            onRevokeChoice: function () {
            }
        },
        function (popup) {
            p = popup;
        },
        function (err) {
        });        
});

document.addEventListener("DOMContentLoaded", function (event) {
    var AllowAllCookiesCtas = document.querySelectorAll(".allow-all-cookies");

    Array.prototype.forEach.call(AllowAllCookiesCtas, function (el, i) {
        var currentCta = AllowAllCookiesCtas[i];

        currentCta.addEventListener("click", function () {
            event.preventDefault();
            var cookieGroups = ["StrictlyNecessary", "PerformanceAnalytics"];
    
            setConsentCookie(cookieGroups, globalCurrentDomain);
            document.location.href = document.location.href;
        });
    });

    var AllowNecessaryCookiesCtas = document.querySelectorAll(".allow-necessary-cookies");

    Array.prototype.forEach.call(AllowNecessaryCookiesCtas, function (el, i) {
        var currentCta = AllowNecessaryCookiesCtas[i];

        currentCta.addEventListener("click", function () {
            event.preventDefault();
            var cookieGroups = ["StrictlyNecessary"];
    
            setConsentCookie(cookieGroups, globalCurrentDomain);
            document.location.href = document.location.href;
        });
    });
});


function setConsentCookie(cookieGroups, domain) {

    var jsonValue = JSON.stringify(cookieGroups);

    var date = new Date();
    date.setTime(date.getTime()+(365*24*60*60*1000));   //  1 year

    if (domain.length > 0) {
        document.cookie = "paag.org:Consent=" + jsonValue + ";expires="+date.toGMTString() + "domain=" + domain + ";path=/";
    } else {
        document.cookie = "paag.org:Consent=" + jsonValue + ";expires="+date.toGMTString() + ";path=/";
    }
}